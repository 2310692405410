import intlTelInput from "intl-tel-input";
// import "../node_modules/intl-tel-input/build/css/intlTelInput.css";

document.addEventListener("DOMContentLoaded", init);

function buttonScroll() {
  const buttonsForScroll = [
    ".button1",
    ".next-step",
    ".back-step",
    ".quiz",
    ".button2",
    ".buttonx",
  ];
  buttonsForScroll.forEach((s) => {
    if (document.querySelector(s)) {
      document.querySelector(s).addEventListener("click", function (e) {
        let target = e.currentTarget;
        const targetBlock = document.querySelector(".quiz");
        if (targetBlock) {
          targetBlock.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  });
}

function initializePopup() {
  const popup = document.getElementById("popupForm");
  const btnOpenPopup = document.querySelectorAll(".call-button");
  const btnClosePopup = document.querySelector(".close-btn");

  btnOpenPopup.forEach((button) => {
    button.addEventListener("click", () => {
      popup.style.display = "block";
      const popupForm = popup.querySelector(".form-with-validation");
      if (popupForm) {
        initializeForm(popupForm);
      }
    });
  });

  btnClosePopup.addEventListener("click", () => {
    popup.style.display = "none";
  });

  window.addEventListener("click", (event) => {
    if (event.target === popup) {
      popup.style.display = "none";
    }
  });
}

function initializeContactMethods(contactMethods) {
  contactMethods.forEach((method) => {
    method.addEventListener("click", () => {
      contactMethods.forEach((m) => m.classList.remove("active"));
      method.classList.add("active");
    });
  });
}

function updateErrorMessage(input, isValid, errorMessage) {
  // Находим ближайший родительский элемент, который имеет класс .field или другой класс вашей обертки
  var fieldContainer = input.closest(".field");
  // Внутри этого элемента находим .error-message
  var errorDiv = fieldContainer.querySelector(".error-message");

  // Затем работаем с errorDiv как обычно
  if (!isValid) {
    errorDiv.textContent = errorMessage;
    errorDiv.style.display = "block";
    input.classList.add("error");
  } else {
    errorDiv.style.display = "none";
    input.classList.remove("error");
  }
  console.log(input, isValid, errorMessage);
}

function initializePhoneInputs(phoneInput, updateSubmitButtonState) {
  console.log("phoneInput", phoneInput);
  if (phoneInput.iti) {
    phoneInput.iti.destroy();
  }

  var viewIn = phoneInput.closest(".iti__selected-flag");

  var iti = intlTelInput(phoneInput, {
    initialCountry: "ru",
    // onlyCountries: ["ru", "th"],
    autoHideDialCode: false,
    autoPlaceholder: "aggressive",
    preferredCountries: ["ru", "th"],
    // dropdownContainer: viewIn,
    useFullscreenPopup: false,
    customPlaceholder: function (selectedCountryPlaceholder) {
      return "" + selectedCountryPlaceholder.replace(/[0-9]/g, "_");
    },
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
  });

  phoneInput.iti = iti;
  // Инициализируем inputmask сразу после intl-tel-input
  iti.promise.then(function () {
    console.log("iti.promise.then");
    var placeholder = phoneInput.getAttribute("placeholder") + "";
    var mask = placeholder.replace(/_/g, "9");
    if (mask !== "undefined") {
      $(phoneInput).inputmask(mask, {
        placeholder: "_",
        clearMaskOnLostFocus: true,
      });
    }
  });

  // keyup вместо input иначе не срабатывает обработчик
  phoneInput.addEventListener("keyup", function () {
    var isPhoneValid = phoneInput.iti.isValidNumber();
    updateErrorMessage(
      phoneInput,
      isPhoneValid,
      "Введите корректный номер телефона",
    );
    updateSubmitButtonState();
  });

  phoneInput.addEventListener("countrychange", function () {
    console.log("focus click countrychange");
    // Можно также обновить валидацию при смене страны
    var isPhoneValid = phoneInput.iti.isValidNumber();
    var placeholder = this.getAttribute("placeholder") + "";
    var isPhoneValid = phoneInput.iti.isValidNumber();
    var newMask = placeholder.replace(/_/g, "9");
    if (newMask !== "undefined" && newMask !== "") {
      // Удаляем предыдущую маску перед установкой новой
      if (this.inputmask) {
        this.inputmask.remove();
      }
      // Устанавливаем новую маску
      Inputmask(newMask, {
        placeholder: "_",
        clearMaskOnLostFocus: true,
        onincomplete: function () {
          updateErrorMessage(
            phoneInput,
            isPhoneValid,
            "Введите корректный номер телефона",
          );
        },
        oncomplete: function () {
          updateErrorMessage(phoneInput, isPhoneValid, "");
        },
      }).mask(phoneInput);
    }
    updateErrorMessage(
      phoneInput,
      isPhoneValid,
      "Введите корректный номер телефона",
    );
    updateSubmitButtonState();
  });
}

function initializeForm(form) {
  const nameInput = form.querySelector('input[name="name"]');
  const phoneInput = form.querySelector(".phoneInput");
  const submitButton = form.querySelector(".submit-quiz");

  initializeContactMethods(form.querySelectorAll(".contact-method"));
  initializePhoneInputs(phoneInput, updateSubmitButtonState);

  nameInput.addEventListener("input", () => {
    const isNameValid = /^[a-zA-Zа-яА-Я ]{2,30}$/.test(nameInput.value);
    updateErrorMessage(nameInput, isNameValid, "Введите ваше имя");
    updateSubmitButtonState();
  });

  submitButton.disabled = true;
  form.addEventListener("submit", onSubmitForm);
}

function updateSubmitButtonState() {
  const forms = document.querySelectorAll(".form-with-validation");
  forms.forEach((form) => {
    const nameInput = form.querySelector('input[name="name"]');
    const phoneInputs = form.querySelectorAll(".phoneInput");
    const submitButton = form.querySelector(".submit-quiz");

    const isNameValid = /^[a-zA-Zа-яА-Я ]{2,30}$/.test(nameInput.value);
    let isAllPhonesValid = Array.from(phoneInputs).every((input) =>
      input.iti.isValidNumber(),
    );
    submitButton.disabled = !(isNameValid && isAllPhonesValid);
  });
}

// let quizAnswers = {};
// function initializeContactMethods(contactMethods) {
//   contactMethods.forEach((method) => {
//     method.addEventListener("click", (e) => {
//       let target = e.target;
//       if (target.classList.contains("active")) {
//         quizAnswers["contact-method"] = target.textContent.trim();
//         console.log(quizAnswers["contact-method"])
//       }
//     });
//   });
// }
//
// function sendForm() {
//   const submitButton = document.querySelector(".submit-quiz");
//   console.log(submitButton)
//   initializeContactMethods(
//     submitButton.parentElement.querySelectorAll(".contact-method"),
//   );
//   submitButton.addEventListener("click", (event) => {
//     event.preventDefault();
//     // добавление данные формы в quizAnswers
//     const formInputs = document.querySelectorAll(
//       ".form-with-validation input",
//     );
//     formInputs.forEach((input) => {
//       if (input.name && input.value) {
//         quizAnswers[input.name] = input.value.trim();
//       }
//     });
//
//     // Отправка данных на сервер
//     fetch("submit_form.php", {
//       method: "POST",
//       body: JSON.stringify(quizAnswers),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => console.log(data))
//       .catch((error) => console.error("Error:", error));
//     console.log("Форма отправлена");
//   });
// }
function onSubmitForm(event) {
  event.preventDefault();
  const submitButton = this.querySelector(".submit-quiz");
  if (!submitButton.disabled) {
    console.log("Форма отправлена");
    // Здесь логика отправки формы
    // sendForm()
  }
}

function openPopup(button, mes) {
  const popup = document.getElementById("popupForm");
  button.addEventListener("click", () => {
    popup.style.display = "block";
    document.querySelector(".contact-method.active").classList.remove("active");
    const popupForm = popup.querySelector(".form-with-validation");
    if (popupForm) {
      initializeForm(popupForm);
    }
    if (mes == "telegram") {
      document.querySelector(".method-telegram").classList.add("active");
    }
    if (mes == "whatsapp") {
      document.querySelector(".method-whatsapp").classList.add("active");
    }
  });
}

function initMessengerButtons() {
  const telegramButtons = document.querySelectorAll("button.telegram");
  const whatsappButtons = document.querySelectorAll("button.whatsapp");

  const contactOptions = document.querySelectorAll(
    ".contact-options .contact-method",
  );

  telegramButtons.forEach((button) => {
    openPopup(button, "telegram");
  });

  whatsappButtons.forEach((button) => {
    openPopup(button, "whatsapp");
  });
}

function init() {
  const forms = document.querySelectorAll(".form-with-validation");
  forms.forEach(initializeForm);

  initializePopup();
  buttonScroll();
  initMessengerButtons();
}
